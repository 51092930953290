@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
* {
  font-family: "Montserrat", sans-serif;
}

body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Styles for error toaster */
.Toastify__toast--error {
  border: 1px solid #eb5757;
  border-radius: 15px !important;
  margin: 8px !important;

  &::after {
    position: absolute;
    color: #333333;
    font-size: 15px;
    font-weight: 700;
    left: 265px;
    padding-top: 14px !important;
  }

  &::before {
    position: relative;
    z-index: 100000;
    left: 12px;
    top: 6px;
  }
}

/* Styles for success toaster */
.Toastify__toast--success {
  border: 1px solid green !important;
  border-radius: 15px !important;
  margin: 8px !important;

  &::before {
    position: relative;
    z-index: 100000;
    left: 12px;
    top: 6px;
  }

  &::after {
    position: absolute;
    color: #333333;
    font-size: 15px;
    font-weight: 700;
    left: 265px;
    padding-top: 14px !important;
  }
}

/* Styles for toaster body */
.Toastify__toast-body {
  color: #444c63;
  font-size: 16px;
  padding-left: 20px;
  line-height: 20px;
  padding: 0px;
  padding-top: 25px;
  width: 100%;
  font-weight: 400;
  margin-left: 16px !important;
}

/* Styles for toaster success/error icon */
.Toastify__toast > button > svg {
  display: none;
}

/* Styles for primary green login button */
.primary-button-custom {
  background-color: rgb(92, 182, 92);
  padding: 12px;
  color: white;
  border-radius: 16px;
  font-weight: 700;
  border: none;
  outline: none;

  /* Hover state */
  &:hover {
    background-color: rgb(72, 162, 72);
    color: white;
  }

  /* Focus state */
  &:focus {
    background-color: rgb(72, 162, 72);
    color: white;
    box-shadow: 0 0 0 3px rgba(72, 162, 72, 0.3);
  }
  &:disabled {
    background-color: grey;
  }
}

.brown-button-custom {
  background-color: rgb(87, 59, 49);
  padding: 12px;
  color: white;
  border-radius: 16px;
  font-weight: 700;
  border: none;
  outline: none;

  /* Hover state */
  &:hover {
    background-color: rgb(84, 62, 52);
    color: white;
  }

  /* Focus state */
  &:focus {
    background-color: rgb(87, 60, 50);
    color: white;
    box-shadow: 0 0 0 4px rgba(87, 60, 50, 0.3);
  }
}

.gold-button-custom {
  background-color: rgb(206, 171, 14);
  padding: 12px;
  color: white;
  border-radius: 16px;
  font-weight: 700;
  border: none;
  outline: none;

  /* Hover state */
  &:hover {
    background-color: rgb(206, 174, 17);
    color: white;
  }

  /* Focus state */
  &:focus {
    background-color: rgb(207, 178, 18);
    color: white;
    box-shadow: 0 0 0 6px rgba(206, 174, 17, 0.3);
  }
}

/* Styles for input boxes green border on focus */
.custom-textfield {
  .MuiOutlinedInput-root {
    .MuiOutlinedInput-notchedOutline {
      border-color: black;
    }

    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: #166534;
      }
    }
  }
}

/* Styles for input boxes floating label of tod  */
.MuiInputLabel-shrink {
  color: #166534 !important;
}

.accordionHeader {
  background-color: rgb(255, 231, 121) !important;
  font-weight: 500;
}

/* Custom style for checkbox step2 */
.custom-checkbox {
  .MuiCheckbox-root {
    color: rgb(92, 182, 92); // unchecked
    &.Mui-checked {
      color: rgb(92, 182, 92); // checked
    }
    transform: scale(1.5);
  }

  .MuiFormControlLabel-label {
    font-size: 1rem;
    margin-left: 4px;
  }
}

/* dropdown height */
.MuiPopover-paper {
  max-height: 150px !important;
}

/* greyed out diable fields */
.MuiInputBase-root.Mui-disabled,
.MuiOutlinedInput-root.Mui-disabled,
.MuiSelect-select.Mui-disabled {
  background-color: #fafafa !important;
}

.MuiTextField-root .MuiInputBase-root.Mui-disabled {
  background-color: #fafafa !important;
}

.MuiInputBase-input.Mui-disabled {
  background-color: #fafafa !important;
}

.unselectedTimeFilter {
  background-color: #ececec;
  border-radius: 2px;
  margin-right: 10px;
  padding: 4px;
  font-size: 12px;
}

.selectedTimeFilter {
  background-color: #cac8c8;
  border-radius: 2px;
  margin-right: 10px;
  padding: 4px;
  font-size: 12px;
  font-weight: 500;
}

.pointer {
  cursor: pointer;
}

.dateFilter {
  font-size: 12px;
  color: #99ac99;
  font-weight: 800;
}

.loadingBar {
  z-index: 1;
  background-color: white;
  position: fixed;
  height: 100vh;
  align-items: center;
  top: 0;
}

.loadingInner {
  width: 95vw;
}


.paramterBox{
  background-color: #fff0af;
  width: 32%;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 80px;
  padding-right: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-color: #fffff3;
  border: 4px solid #ffffcf;
  border-radius: 14px;
  margin-bottom: 30px;
}

.powerGreen {
  border: 4px solid #caefca;
  border-color: #fffff3;
  background-color: #82d682;
}

.savingBar{
  background-color: black;
  padding: 10px;
  color: white;
}